import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import {
  AddNewButton,
  Button,
  Card,
  Divider,
  Dropdown,
  MoreButton,
  Spinner,
  TextInput,
} from '@makeably/creativex-design-system';
import Tabs from 'components/molecules/Tabs';
import ConfigurableTable from 'components/organisms/ConfigurableTable';
import MappingWarningModal, {
  VARIANTS as warningModalVariants,
} from 'components/taxonomies/MappingWarningModal';
import { getCsv } from 'utilities/csv';
import { saveFile } from 'utilities/file';
import { generateItemMatches } from 'utilities/itemElement';
import {
  bulkCreateTaxonomyCompanyMarketCodesPath,
  apiCompanyMarketCodePath,
  apiCompanyMarketCodesPath,
  isoDefaultsApiCompanyMarketCodesPath,
  apiMarketsPath,
  changeImpactApiCompanyMarketCodesPath,
} from 'utilities/routes';
import {
  get,
  post,
  patch,
  destroy,
} from 'utilities/toastyRequests';
import styles from 'components/taxonomies/Mappings.module.css';

const views = Object.freeze({
  LIST: Symbol('list'),
  CODE_INPUT: Symbol('code_input'),
  MARKET_SELECT: Symbol('market_select'),
});

const getMarketCodes = async () => {
  const response = await get(apiCompanyMarketCodesPath());

  return response.data.items;
};

const getIsoMarketCodes = async () => {
  const response = await get(isoDefaultsApiCompanyMarketCodesPath());

  return response.data.items;
};

const getMarkets = async () => {
  const response = await get(apiMarketsPath());

  return response.data.items;
};

const createMarket = async ({ marketCode, marketId }) => {
  const response = await post(apiCompanyMarketCodesPath(), {
    marketCode,
    marketId,
  }, {
    successMessage: `Market code "${marketCode}" has been successfully added!`,
    showErrorsFromResponse: true,
  });

  return response.ok;
};

const updateMarket = async ({
  id, marketCode, marketId,
}) => {
  const response = await patch(apiCompanyMarketCodePath(id), {
    marketCode,
    marketId,
  }, {
    successMessage: `Market code "${marketCode}" has been successfully updated!`,
    showErrorsFromResponse: true,
  });

  return response.ok;
};

const deleteMarket = async ({ id }) => {
  const response = await destroy(apiCompanyMarketCodePath(id), {
    successMessage: 'Market successfully deleted!',
    showErrorsFromResponse: true,
  });

  return response.ok;
};

const getCodeChangeImpact = async ({ marketCode }) => {
  const response = await post(changeImpactApiCompanyMarketCodesPath(), {
    marketCode,
  }, {
    showToasts: false,
  });

  return response.data;
};

function Markets() {
  const [view, setView] = useState(views.LIST);
  const [companyMarketCodes, setCompanyMarketCodes] = useState([]);
  const [isoMarketCodes, setIsoMarketCodes] = useState([]);
  const [selectedTab, setSelectedTab] = useState('all');
  const [itemTabs, setItemTabs] = useState({});
  const [tabLabels, setTabLabels] = useState([]);
  const [visibleMarketCodes, setVisibleMarketCodes] = useState([]);
  const [marketOptions, setMarketOptions] = useState([]);
  const [operatingMarketCode, setOperatingMarketCode] = useState({
    id: undefined,
    marketCode: '',
    marketId: undefined,
  });
  const [affectedCount, setAffectedCount] = useState();
  const [existingMarketCode, setExistingMarketCode] = useState({
    marketCode: '',
    marketId: undefined,
  });
  const [warningModalVariant, setWarningModalVariant] = useState(warningModalVariants.HIDDEN);
  const [isLoading, setLoading] = useState(true);

  const reloadMarketCodes = async () => {
    setLoading(true);

    const marketCodes = await getMarketCodes();
    setCompanyMarketCodes(marketCodes);

    setView(views.LIST);
    setWarningModalVariant(warningModalVariants.HIDDEN);
    setSelectedTab('all');
    setLoading(false);
  };

  const onCreateConfirm = async () => {
    setLoading(true);
    const success = await createMarket(operatingMarketCode);

    if (success) {
      await reloadMarketCodes();
    } else {
      setLoading(false);
    }
  };

  const onDeleteConfirm = async () => {
    setLoading(true);
    await deleteMarket(operatingMarketCode);

    await reloadMarketCodes();
  };

  const onUpdateConfirm = async () => {
    setLoading(true);
    const success = await updateMarket(operatingMarketCode);

    if (success) {
      await reloadMarketCodes();
    } else {
      setLoading(false);
    }
  };

  const onSaveClick = async () => {
    if (operatingMarketCode.id) {
      // Update
      setWarningModalVariant(warningModalVariants.UPDATE);
    } else {
      const isoMarketCode = isoMarketCodes.find(
        (isoCode) => isoCode.marketCode === operatingMarketCode.marketCode,
      );
      setExistingMarketCode(isoMarketCode);

      if (isoMarketCode) {
        // Create (iso override)
        setWarningModalVariant(warningModalVariants.CREATE);
      } else {
        // Create
        await onCreateConfirm();
      }
    }
  };

  const onEditClick = (companyMarketCode) => {
    setOperatingMarketCode(companyMarketCode);
    setExistingMarketCode(companyMarketCode);
    setView(views.MARKET_SELECT);
  };

  const onDeleteClick = (companyMarketCode) => {
    setOperatingMarketCode(companyMarketCode);
    setExistingMarketCode(isoMarketCodes.find(
      (isoCode) => isoCode.marketCode === companyMarketCode.marketCode,
    ));
    setWarningModalVariant(warningModalVariants.DELETE);
  };

  const onDownloadCsvClick = () => {
    const csv = getCsv(visibleMarketCodes, [
      {
        key: 'marketCode',
        label: 'Taxonomy Market Code',
      },
      {
        key: 'marketName',
        label: 'CreativeX Market',
      },
    ]);
    saveFile('market_codes.csv', csv);
  };

  const renderEditButton = (companyMarketCode, matches) => {
    const { marketCode } = companyMarketCode;
    return (
      <button
        className={styles.linkButton}
        type="button"
        onClick={() => onEditClick(companyMarketCode)}
      >
        { generateItemMatches(marketCode, matches) }
      </button>
    );
  };

  const renderDeleteButton = (companyMarketCode) => (
    companyMarketCode.id && (
    <button
      className={classNames(styles.linkButton, styles.warningButton)}
      type="button"
      onClick={() => onDeleteClick(companyMarketCode)}
    >
      Delete
    </button>
    )
  );

  useEffect(() => {
    (async () => {
      const marketCodes = await getMarketCodes();
      setCompanyMarketCodes(marketCodes);

      const isoMarkets = await getIsoMarketCodes();
      setIsoMarketCodes(isoMarkets);

      const markets = await getMarkets();
      setMarketOptions(markets.map((market) => ({
        label: market.name,
        value: market.id,
      })));

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    const allCodes = companyMarketCodes.concat(...isoMarketCodes.filter(
      (isoCode) => !companyMarketCodes.find(
        (customCode) => customCode.marketCode === isoCode.marketCode,
      ),
    ));

    const tabbedItems = {
      all: {
        label: 'All',
        items: allCodes,
      },
      custom: {
        label: 'Custom',
        items: companyMarketCodes,
      },
      iso: {
        label: 'ISO defaults',
        items: isoMarketCodes,
      },
    };
    setItemTabs(tabbedItems);
  }, [companyMarketCodes, isoMarketCodes]);

  useEffect(() => {
    setTabLabels(Object.entries(itemTabs).map(([key, tab]) => {
      const count = tab.items?.length ?? 0;
      return {
        key,
        label: `${tab.label} (${count})`,
      };
    }));
  }, [itemTabs]);

  useEffect(() => {
    setVisibleMarketCodes(itemTabs[selectedTab]?.items ?? []);
  }, [selectedTab, itemTabs]);

  useEffect(() => {
    (async () => {
      if (warningModalVariant === warningModalVariants.HIDDEN) {
        setAffectedCount(undefined);
      } else {
        const impact = await getCodeChangeImpact(operatingMarketCode);
        setAffectedCount(impact.count);
      }
    })();
  }, [warningModalVariant, operatingMarketCode]);

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      { view === views.LIST && (
      <Card padding={false}>
        <div className="u-flexRow u-justifyBetween">
          <Tabs
            selected={selectedTab}
            tabs={tabLabels}
            onSelectedChange={setSelectedTab}
          />
          <div className="u-flexRow u-marginTop-16 u-marginBottom-16 u-marginRight-8 u-flexAlignCenter">
            <div className="u-marginRight-16">
              <AddNewButton
                disabled={isLoading}
                label="Add New"
                onClick={() => {
                  setOperatingMarketCode({ marketCode: '' });
                  setView(views.CODE_INPUT);
                }}
              />
            </div>
            <Divider vertical />
            <MoreButton
              menuSize="large"
              options={[
                {
                  label: 'Bulk Upload Market Codes via CSV',
                  url: bulkCreateTaxonomyCompanyMarketCodesPath(),
                },
                {
                  label: 'Download CSV',
                  onClick: onDownloadCsvClick,
                },
              ]}
            />
          </div>
        </div>
        <ConfigurableTable
          columnConfigs={{
            marketCode: {
              filter: { disabled: true },
              header: 'CODE',
              renderFn: renderEditButton,
            },
            marketName: {
              header: 'MARKET',
              filter: { label: 'MARKET' },
            },
            deleteAction: {
              filter: { disabled: true },
              header: 'ACTION',
              search: { disabled: true },
              renderFn: renderDeleteButton,
            },
          }}
          items={visibleMarketCodes}
        />
      </Card>
      ) }
      { view === views.CODE_INPUT && (
      <Card>
        <h4>Step 1: Add New Code</h4>
        <div className="u-marginTop-16 u-marginBottom-16">
          <Divider />
        </div>
        <TextInput
          isError={false}
          label="Add New Code"
          name="code"
          placeholder="Enter"
          type="text"
          value={operatingMarketCode.marketCode}
          onChange={(code) => setOperatingMarketCode(
            (prev) => ({
              ...prev,
              marketCode: code.toUpperCase(),
            }),
          )}
        />
        <div className="u-marginTop-16 u-marginBottom-16">
          <Divider />
        </div>
        <div className="u-flexRow u-marginTop-16 u-justifyEnd u-gap-16">
          <Button
            disabled={isLoading}
            label="Cancel"
            variant="tertiary"
            onClick={() => setView(views.LIST)}
          />
          <Button
            disabled={isLoading || !operatingMarketCode.marketCode.length}
            label="Next"
            onClick={() => setView(views.MARKET_SELECT)}
          />
        </div>
      </Card>
      ) }
      { view === views.MARKET_SELECT && (
      <Card>
        <div className="u-flexRow u-justifyBetween">
          <h4>
            Code:
            { operatingMarketCode.marketCode }
          </h4>
          { operatingMarketCode.id && (
          <MoreButton options={[{
            label: 'Delete Code',
            onClick: () => {
              // Reset any changes to the existing values (for the warning modal)
              setOperatingMarketCode(companyMarketCodes.find(
                (existingCode) => existingCode.id === operatingMarketCode.id,
              ));
              setExistingMarketCode(isoMarketCodes.find(
                (iso) => iso.marketCode === operatingMarketCode.marketCode,
              ));
              setWarningModalVariant(warningModalVariants.DELETE);
            },
          }]}
          />
          ) }
        </div>
        <div className="u-marginTop-16 u-marginBottom-16">
          <Divider />
        </div>
        <Dropdown
          label="Select Market"
          options={marketOptions}
          selected={marketOptions.find(
            (market) => market.label === operatingMarketCode.marketName,
          )}
          onChange={(option) => setOperatingMarketCode(
            (prev) => ({
              ...prev,
              marketId: option.value,
              marketName: option.label,
            }),
          )}
        />
        <div className="u-marginTop-16 u-marginBottom-16">
          <Divider />
        </div>
        <div className="u-flexRow u-marginTop-16 u-justifyEnd u-gap-16">
          <Button
            disabled={isLoading}
            label="Cancel"
            variant="tertiary"
            onClick={() => setView(views.LIST)}
          />
          <Button
            disabled={isLoading || !operatingMarketCode.marketId}
            label="Save"
            onClick={onSaveClick}
          />
        </div>
      </Card>
      ) }
      <MappingWarningModal
        affectedCount={affectedCount}
        code={operatingMarketCode.marketCode}
        dataType="market"
        defaultCode={existingMarketCode?.marketCode}
        defaultName={existingMarketCode?.marketName}
        name={operatingMarketCode.marketName}
        variant={warningModalVariant}
        onClose={() => setWarningModalVariant(warningModalVariants.HIDDEN)}
        onCreateConfirm={onCreateConfirm}
        onDeleteConfirm={onDeleteConfirm}
        onUpdateConfirm={onUpdateConfirm}
      />
    </>
  );
}

export default Markets;
