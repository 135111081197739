import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import {
  AddNewButton,
  Button,
  Card,
  Divider,
  Dropdown,
  Spinner,
  TextInput,
  MoreButton,
} from '@makeably/creativex-design-system';
import ConfigurableTable from 'components/organisms/ConfigurableTable';
import MappingWarningModal, { VARIANTS as warningModalVariants } from 'components/taxonomies/MappingWarningModal';
import { getCsv } from 'utilities/csv';
import { saveFile } from 'utilities/file';
import { generateItemMatches } from 'utilities/itemElement';
import {
  apiBrandsPath,
  apiCompanyBrandCodePath,
  apiCompanyBrandCodesPath,
  bulkCreateTaxonomyCompanyBrandCodesPath,
  changeImpactApiCompanyBrandCodesPath,
} from 'utilities/routes';
import {
  get,
  post,
  patch,
  destroy,
} from 'utilities/toastyRequests';
import styles from 'components/taxonomies/Mappings.module.css';

const views = Object.freeze({
  LIST: Symbol('list'),
  CODE_INPUT: Symbol('code_input'),
  BRAND_SELECT: Symbol('brand_select'),
});

const getBrands = async () => {
  const response = await get(apiBrandsPath());

  return response.data.items;
};

const getBrandCodes = async () => {
  const response = await get(apiCompanyBrandCodesPath());

  return response.data.items;
};

const createBrand = async ({ brandCode, brandId }) => {
  const response = await post(apiCompanyBrandCodesPath(), {
    brandCode,
    organizationBrandId: brandId,
  }, {
    successMessage: `Brand code "${brandCode}" has been successfully added!`,
    showErrorsFromResponse: true,
  });

  return response.ok;
};

const updateBrand = async ({
  id, brandCode, brandId,
}) => {
  const response = await patch(apiCompanyBrandCodePath(id), {
    brandCode,
    organizationBrandId: brandId,
  }, {
    successMessage: `Brand code "${brandCode}" has been successfully updated!`,
    showErrorsFromResponse: true,
  });

  return response.ok;
};

const deleteBrand = async ({ id }) => {
  const response = await destroy(apiCompanyBrandCodePath(id), {
    successMessage: 'Brand successfully deleted',
    showErrorsFromResponse: true,
  });

  return response.ok;
};

const getCodeChangeImpact = async ({ brandCode }) => {
  const response = await post(changeImpactApiCompanyBrandCodesPath(), {
    brandCode,
  }, {
    showToasts: false,
  });

  return response.data;
};

function Brands() {
  const [view, setView] = useState(views.LIST);
  const [companyBrandCodes, setCompanyBrandCodes] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [operatingBrandCode, setOperatingBrandCode] = useState({
    id: undefined,
    brandCode: '',
    brandId: undefined,
  });
  const [affectedCount, setAffectedCount] = useState();
  const [warningModalVariant, setWarningModalVariant] = useState(warningModalVariants.HIDDEN);
  const [isLoading, setLoading] = useState(true);

  const reloadBrandCodes = async () => {
    setLoading(true);

    const brandCodes = await getBrandCodes();
    setCompanyBrandCodes(brandCodes);

    setView(views.LIST);
    setWarningModalVariant(warningModalVariants.HIDDEN);
    setLoading(false);
  };

  const onDeleteConfirm = async () => {
    setLoading(true);
    await deleteBrand(operatingBrandCode);

    await reloadBrandCodes();
  };

  const onUpdateConfirm = async () => {
    setLoading(true);
    const success = await updateBrand(operatingBrandCode);

    if (success) {
      await reloadBrandCodes();
    } else {
      setLoading(false);
    }
  };

  const onSaveClick = async () => {
    if (operatingBrandCode.id) {
      // Update
      setWarningModalVariant(warningModalVariants.UPDATE);
    } else {
      // Create
      setLoading(true);
      const success = await createBrand(operatingBrandCode);

      if (success) {
        await reloadBrandCodes();
      } else {
        setLoading(false);
      }
    }
  };

  const onDeleteClick = (companyBrandCode) => {
    setOperatingBrandCode(companyBrandCode);
    setWarningModalVariant(warningModalVariants.DELETE);
  };

  const onEditClick = (companyBrandCode) => {
    setOperatingBrandCode(companyBrandCode);
    setView(views.BRAND_SELECT);
  };

  const onDownloadCsvClick = () => {
    const csv = getCsv(companyBrandCodes, [
      {
        key: 'brandCode',
        label: 'Taxonomy Brand Code',
      },
      {
        key: 'brandName',
        label: 'CreativeX Brand',
      },
    ]);
    saveFile('brand_codes.csv', csv);
  };

  const renderEditButton = (companyBrandCode, matches) => (
    <button
      className={styles.linkButton}
      type="button"
      onClick={() => onEditClick(companyBrandCode)}
    >
      { generateItemMatches(companyBrandCode.brandCode, matches) }
    </button>
  );

  const renderDeleteButton = (companyBrandCode) => (
    <button
      className={classNames(styles.linkButton, styles.warningButton)}
      type="button"
      onClick={() => onDeleteClick(companyBrandCode)}
    >
      Delete
    </button>
  );

  useEffect(() => {
    (async () => {
      const brandCodes = await getBrandCodes();
      setCompanyBrandCodes(brandCodes);

      const brands = await getBrands();
      setBrandOptions(brands.map((brand) => ({
        label: brand.name,
        value: brand.id,
      })));

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (warningModalVariant === warningModalVariants.HIDDEN) {
        setAffectedCount(undefined);
      } else {
        const impact = await getCodeChangeImpact(operatingBrandCode);
        setAffectedCount(impact.count);
      }
    })();
  }, [warningModalVariant, operatingBrandCode]);

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      { view === views.LIST
            && (
            <Card padding={false}>
              <div className="u-flexRow u-justifyFlexEnd">
                <div className="u-flexRow u-marginTop-16 u-marginBottom-16 u-marginRight-8 u-flexAlignCenter">
                  <div className="u-marginRight-16">
                    <AddNewButton
                      disabled={isLoading}
                      label="Add New"
                      onClick={() => {
                        setOperatingBrandCode({ brandCode: '' });
                        setView(views.CODE_INPUT);
                      }}
                    />
                  </div>
                  <Divider vertical />
                  <MoreButton
                    menuSize="large"
                    options={[
                      {
                        label: 'Bulk Upload Brand Codes via CSV',
                        url: bulkCreateTaxonomyCompanyBrandCodesPath(),
                      },
                      {
                        label: 'Download CSV',
                        onClick: onDownloadCsvClick,
                      },
                    ]}
                  />
                </div>
              </div>
              <ConfigurableTable
                columnConfigs={{
                  brandCode: {
                    filter: { disabled: true },
                    header: 'CODE',
                    renderFn: renderEditButton,
                  },
                  brandName: {
                    header: 'BRAND',
                    filter: { label: 'BRAND' },
                  },
                  deleteAction: {
                    filter: { disabled: true },
                    header: 'ACTION',
                    search: { disabled: true },
                    renderFn: renderDeleteButton,
                  },
                }}
                items={companyBrandCodes}
              />
            </Card>
            ) }
      { view === views.CODE_INPUT
            && (
            <Card>
              <h4>Step 1: Add New Code</h4>
              <div className="u-marginTop-16 u-marginBottom-16">
                <Divider />
              </div>
              <TextInput
                isError={false}
                label="Add New Code"
                name="code"
                placeholder="Enter"
                type="text"
                value={operatingBrandCode.brandCode}
                onChange={(code) => setOperatingBrandCode(
                  (prev) => ({
                    ...prev,
                    brandCode: code.toUpperCase(),
                  }),
                )}
              />
              <div className="u-marginTop-16 u-marginBottom-16">
                <Divider />
              </div>
              <div className="u-flexRow u-marginTop-16 u-justifyEnd u-gap-16">
                <Button
                  disabled={isLoading}
                  label="Cancel"
                  variant="tertiary"
                  onClick={() => setView(views.LIST)}
                />
                <Button
                  disabled={isLoading || !operatingBrandCode.brandCode.length}
                  label="Next"
                  onClick={() => setView(views.BRAND_SELECT)}
                />
              </div>
            </Card>
            ) }
      { view === views.BRAND_SELECT
            && (
            <Card>
              <div className="u-flexRow u-justifyBetween">
                <h4>
                  Code:
                  { operatingBrandCode.brandCode }
                </h4>
                { operatingBrandCode.id
                    && (
                    <MoreButton options={[{
                      label: 'Delete Code',
                      onClick: () => {
                        // Reset any changes to the existing values (for the warning modal display)
                        setOperatingBrandCode(companyBrandCodes.find(
                          (existingCode) => existingCode.id === operatingBrandCode.id,
                        ));
                        setWarningModalVariant(warningModalVariants.DELETE);
                      },
                    }]}
                    />
                    ) }
              </div>
              <div className="u-marginTop-16 u-marginBottom-16">
                <Divider />
              </div>
              <Dropdown
                label="Select Brand"
                options={brandOptions}
                selected={brandOptions.find(
                  (brand) => brand.label === operatingBrandCode.brandName,
                )}
                onChange={(option) => setOperatingBrandCode(
                  (prev) => ({
                    ...prev,
                    brandId: option.value,
                    brandName: option.label,
                  }),
                )}
              />
              <div className="u-marginTop-16 u-marginBottom-16">
                <Divider />
              </div>
              <div className="u-flexRow u-marginTop-16 u-justifyEnd u-gap-16">
                <Button
                  disabled={isLoading}
                  label="Cancel"
                  variant="tertiary"
                  onClick={() => setView(views.LIST)}
                />
                <Button
                  disabled={isLoading || !operatingBrandCode.brandId}
                  label="Save"
                  onClick={onSaveClick}
                />
              </div>
            </Card>
            ) }
      <MappingWarningModal
        affectedCount={affectedCount}
        code={operatingBrandCode.brandCode}
        dataType="brand"
        name={operatingBrandCode.brandName}
        variant={warningModalVariant}
        onClose={() => setWarningModalVariant(warningModalVariants.HIDDEN)}
        onDeleteConfirm={onDeleteConfirm}
        onUpdateConfirm={onUpdateConfirm}
      />
    </>
  );
}

export default Brands;
